<template>
  <div>
    <cms-header></cms-header>
    <div class="zone-home cms-container">
      <div id="navigation-carousel" class="cms-sm-hidden cms-ssm-hidden">
        <el-carousel height="100%">
          <el-carousel-item v-for="item in carouselList" v-bind:key="item.id">
            <a v-for="(imgItem) in item.imagesUrl" v-bind:key="imgItem.id">
              <div
                  v-bind:style="{'background-image': 'url(' + imgItem.url + ')','background-repeat':'no-repeat','background-size':'cover','width':'100%','height':'100%','background-position': 'center'}">
                <img :src="imgItem.url" style="z-index: 1"
                     class="w-100 h-100 cms-xl-hidden cms-lg-hidden  cms-md-hidden cms-sm-hidden navigation-carousel-img"
                     alt="">
              </div>
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <el-carousel class="cms-lg-hidden cms-xl-hidden cms-md-hidden" id="app-carousel" :height="bannerHeight+'px'">
        <el-carousel-item v-for="(items,index) in carouselList" :key="'items-'+index" interval="3000">
          <el-row>
            <el-col v-for="(item ,i) in items.imagesUrl" :key="'item'+i" class="curse">
              <img ref="bannerHeight" class="w-100" :src="item.url" alt="" @load="imgLoad">
            </el-col>
          </el-row>
        </el-carousel-item>
      </el-carousel>
      <div class="latest-stay">
        <div class="new-title left"><span>最新入驻</span></div>
        <div class="left latest-stay-company">
          <div class="row" :class="{'animate-up': animateUp}">
            <div v-for="(item,index) in latestStay" :key="'sup-1-'+index"
                 class="left col-12 col-sm-6 col-lg-4 latest-stay-company-item">
              {{ item.companyName }} &nbsp;<img style="margin-top: 5PX" width="26" height="12"
                                                src="../../assets/image/pc/形状@2x.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="high-purchase">
        <div class="new-title"><span>优质招标方</span></div>
        <div class=" row purchase-content">
          <div class="purchase-content-items col-md-4 col-lg-3 col-sm-6 col-6" v-for="(item,index) in buyerList"
               :key="'purchase_'+index">
            <router-link class="span-color" v-if="item.newVersionZone == '1'"
                         :to="{name:'specializedNewZone','query':{'companyId':item.id,'id':item.companyId}}">
              <div class="purchase-content-item">
                <div class="purchase-content-item-img" v-if="item.imagesUrl.length == 0">
                </div>
                <div class="purchase-content-item-img" v-for="(it,i) in item.imagesUrl" :key="'it'+i">
                  <img :src="it.url" class="h-100">
                </div>
                <div class="purchase-content-item-company show-one-line" :title="item.title">{{ item.title }}</div>
                <div class="purchase-content-item-span show-one-line">所属行业：{{ item.industry }}</div>
                <div class="purchase-content-item-span show-one-line">经营范围：{{ item.businessScope }}</div>
              </div>
            </router-link>
            <router-link class="span-color" v-else-if="item.newVersionZone == '2'"
                         :to="{name: item.companyId == '6802' ?'specializedZoneYx':'specializedNewZoneTwo','query':{'companyId':item.id,'id':item.companyId}}">
              <div class="purchase-content-item">
                <div class="purchase-content-item-img" v-if="item.imagesUrl.length == 0">
                </div>
                <div class="purchase-content-item-img" v-for="(it,i) in item.imagesUrl" :key="'it'+i">
                  <img :src="it.url" class="h-100">
                </div>
                <div class="purchase-content-item-company show-one-line" :title="item.title">{{ item.title }}</div>
                <div class="purchase-content-item-span show-one-line">所属行业：{{ item.industry }}</div>
                <div class="purchase-content-item-span show-one-line">经营范围：{{ item.businessScope }}</div>
              </div>
            </router-link>
            <router-link class="span-color" v-else
                         :to="{name:'zone','query':{'companyId':item.id,'id':item.companyId}}">
              <div class="purchase-content-item">
                <div class="purchase-content-item-img" v-if="item.imagesUrl.length == 0">
                </div>
                <div class="purchase-content-item-img" v-for="(it,i) in item.imagesUrl" :key="'it'+i">
                  <img :src="it.url" class="h-100">
                </div>
                <div class="purchase-content-item-company show-one-line" :title="item.title">{{ item.title }}</div>
                <div class="purchase-content-item-span show-one-line">所属行业：{{ item.industry }}</div>
                <div class="purchase-content-item-span show-one-line">经营范围：{{ item.businessScope }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="high-purchase">
        <div class="new-title"><span>精选投标方</span></div>
        <div class=" row purchase-content">
          <div class="purchase-content-items col-md-4 col-lg-3 col-sm-6 col-6" v-for="(item,index) in supplierList"
               :key="'supplier_'+index">
            <div class="purchase-content-item">
              <div class="purchase-content-item-img" v-if="item.imagesUrl.length == 0">
              </div>
              <div class="purchase-content-item-img" v-for="(it,i) in item.imagesUrl" :key="'it'+i">
                <img :src="it.url" class="h-100">
              </div>

              <div class="purchase-content-item-company show-one-line" :title="item.title">{{ item.title }}</div>
              <div class="purchase-content-item-span show-one-line">所属行业：{{ item.industry }}</div>
              <div class="purchase-content-item-span show-one-line">经营范围：{{ item.businessScope }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="high-purchase">
        <div class="new-title"><span>合作企业</span></div>
        <div class="row purchase-content">
          <div class="col-12 col-md-6 cooperate-bottom" style="height: 100px;" v-for="(item,index) in agencyList" :key="'agency_'+index">
            <a :href="item.linkUrl" target="_blank">
              <div class="purchase-content-show" v-for="(it,i) in item.imagesUrl" :key="'it-t-t'+i"
                   v-bind:style="{'background-image': 'url(' + it.url + ')','background-repeat':'no-repeat','background-size':'100% 100%','display': 'block','width':'100%','height':'100%'}">
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <cms-foot class="left"></cms-foot>
  </div>
</template>

<script>
import axios from "axios";
import CmsHeader from "../../components/commoon/component/cms-header";
import CmsFoot from "../new-home/cms-foot";
export default {
  name: "zone-home",
  components: {CmsFoot, CmsHeader},
  data() {
    return {
      carouselList: [],
      buyerList: [],
      animateUp: false,
      timer: null,
      latestStay: [],
      supplierList: [],
      agencyList: [],
      categoryList: [],
      bannerHeight: ""
    }
  }, created() {
    this.selectPromotionList("carousel", "carousel", "carouselList");
    /*初始化优质采购商*/
    this.selectPromotionList("superior_suppliers", "buyer", "buyerList");
    //初始化精选供应商
    this.selectPromotionList("featured_suppliers", "featured_suppliers", "supplierList");
    //初始化合作
    this.selectPromotionList("agency", "agency", "agencyList");
    this.selectNewSupplier();
    document.title = this.title;
    let that = this;
    this.$nextTick(() => {
      setTimeout(function () {
        //延时加载，解决ios默认浏览器问题
        that.imgLoad();
      }, 500);
    })
  },
  mounted() {
    this.timer = setInterval(this.scrollAnimate, 1500);
    window.addEventListener('resize', () => {
      this.bannerHeight = this.$refs.bannerHeight[0].height;
      this.imgLoad();
    }, true)
  },
  methods: {
    imgLoad() {
      let that = this;
      that.$nextTick(() => {
        that.bannerHeight = that.$refs.bannerHeight[0].height;
      })
    },
    scrollAnimate() {
      this.animateUp = true
      setTimeout(() => {
        this.latestStay.push(this.latestStay[0])
        this.latestStay.shift()
        this.animateUp = false;
      }, 2500)
    },
    destroyed() {
      clearInterval(this.timer)
    },
    selectCategoryList: function (modelId, name) {
      let that = this;
      axios.get(that.base + "/category/list.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
      })
    },
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      if (modelId == 'zone') {
        axios.get(this.base + "/category/get.do", {
          params: {
            modelId: modelId
          }
        }).then((data) => {
          data.data.result.children.some((item) => {
            if (item.modelId != 'cooperate_zone') {
              that.categoryList.push(item.id);
            }
          })
          axios.post(this.base + "/promotion/getPromotionList.do", {
            page: {},
            cmsPromotionDTO: {
              categoryIdList: that.categoryList
            }
          }).then((da) => {
            for (var i = 0; i < da.data.result.length; i++) {
              if (da.data.result[i].imagesUrl != null && da.data.result[i].imagesUrl != "") {
                da.data.result[i].imagesUrl = JSON.parse(da.data.result[i].imagesUrl);
              }
              if (da.data.result[i].logoUrl != null && da.data.result[i].logoUrl != "") {
                da.data.result[i].logoUrl = JSON.parse(da.data.result[i].logoUrl);
              }
            }
            that[entityListName] = da.data.result;
          });
        })
      } else {
        axios.get(this.base + "/category/get.do", {
          params: {
            modelId: modelId
          }
        }).then((data) => {
          that[entityName] = data.data.result;
          var categoryId = data.data.result.id;
          axios.get(that.base + "/promotion/list.do", {
            params: {
              categoryId: categoryId
            }
          }).then((data) => {
            that.zhuanquSize = data.data.result.length;
            for (var i = 0; i < data.data.result.length; i++) {
              if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
                data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
              }
            }
            that[entityListName] = data.data.result;
          })
        })
      }
    },
    selectNewSupplier: function () {
      var that = this;
      axios.post(this.base + "/promotion/getLatestSupplier.do", {
        page: {
          current: 0,
          size: 9
        },
        cmsContentDTO: {}
      }).then((data) => {
        that.latestStay = data.data.result.records;
      });
    },
  }
}
</script>

<style scoped>
.zone-home {
  background: #F4F4F4;
}
.purchase-content-item-img img{
  max-width: 100%;
}
.cooperate-bottom {
  margin-bottom: 10px;
}

.latest-stay {
  margin-top: 10px;
  height: 68px;
  background: #FFFFFF;
  padding: 20px 20px 20px 0px;
  overflow: hidden;
}

.new-title {
  width: 140px;
  font-size: 1.7rem;
  font-weight: bold;
  color: #423F42;
  border-left: 5px solid red;
  padding-left: 15px;
  margin-bottom: 15px;
}

.latest-stay-company {
  width: 1000px;
  height: 25px;
  overflow: hidden;
}

.latest-stay-company-item {
  font-size: 1.4rem;
  font-weight: 500;
  color: #666666;
  display: flex;
  align-content: center;
  justify-content: center;
  line-height: 25px;
}

.purchase-content-items {
  height: auto;
  background: #FFFFFF;
  margin-bottom: 20px;
}

.purchase-content-item {
  border: 1px solid #E5E5E5;
  height: 100%;
  background: #FFFFFF;
  padding: 10px 20px 10px 20px;
}


.high-purchase {
  margin-top: 10px;
  padding-top: 15px;
  background: #FFFFFF;
  margin-bottom: 10px;
}

.shop {
  width: 100%;
  background-image: url("../../assets/image/new-home/icon_shop.png");
  background-repeat: no-repeat;
  height: 141px;
  background-size: cover;
  background-position: center center;
}

.purchase-content {
  margin: 0px;
  padding: 10px 0px 10px 0px;
}

.purchase-content-item-img {
  overflow: hidden;
  height: 58px;
}

.purchase-content-item-company {
  font-weight: 500;
  color: #423F42;
  font-size: 1.4rem;
  margin-bottom: 4px;
}

.purchase-content-item-span {
  color: #999999;
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 4px;
}

.purchase-content-show {
  border: 0.2px solid #E5E5E5;
  margin-bottom: 10px;
  padding: 10px;
}

@media screen and (max-width: 576px) {
  #navigation-carousel {
    width: 100%;
    height: 248px;
    background: #EDEDED;
  }

  .purchase-content-item-img {
    padding-left: 0px;
    overflow: hidden;
  }

  .purchase-content-items {
    padding: 10px;
    height: auto;
  }

  .purchase-content-item {
    padding: 10px 10px 10px 10px;
  }

  .latest-stay-company-item {
    justify-content: left;
  }

  .latest-stay-company {
    margin-left: 10px;
    width: auto;
    height: 25px;
    overflow: hidden;
    text-align: left;
  }

  .latest-stay {
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  #navigation-carousel {
    width: 100%;
    background: #EDEDED;
    margin: 0px 0px 0px 0px;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 1.4rem;
    opacity: 0.75;
    height: 100% !important;
    margin: 0;
  }

  .el-carousel {
    height: 100%;
  }

  .latest-stay {
    height: auto;
  }

  .latest-stay-company {
    width: auto;
    height: 25px;
    overflow: hidden;
    margin-left: 10px;
  }

  .latest-stay-company-item {
    justify-content: left;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #navigation-carousel {
    width: 720px;
    height: 180px !important;
    background: #EDEDED;
  }

  #navigation-carousel .el-carousel {
    height: 180px !important;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 1.4rem;
    opacity: 0.75;
    height: 100% !important;
    margin: 0;
  }

  .el-carousel {
    height: 100%;
  }

  .latest-stay-company {
    width: 560px;
    height: 25px;
    overflow: hidden;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  #navigation-carousel {
    width: 960px;
    height: 240px;
    background: #EDEDED;
  }

  #navigation-carousel .el-carousel {
    height: 240px !important;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 1.4rem;
    opacity: 0.75;
    height: 100% !important;
    margin: 0;
  }

  .el-carousel {
    height: 100%;
  }

  .latest-stay-company {
    width: 800px;
    height: 25px;
    overflow: hidden;
  }
}

@media (min-width: 1200px) {
  #navigation-carousel {
    width: 1160px;
    height: 290px !important;
    background: #EDEDED;
  }

  #navigation-carousel .el-carousel {
    height: 290px !important;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 1.4rem;
    opacity: 0.75;
    height: 100% !important;
    margin: 0;
  }

  .el-carousel {
    height: 100%;
  }
}
</style>
